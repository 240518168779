import { Mutex } from 'async-mutex';
import { stringify } from 'qs';
import { toast } from 'react-toastify';

import { LS_ACCESS_TOKEN_KEY, LS_REFRESH_TOKEN_KEY } from '@/constants/auth';
import { useAuthStore } from '@/stores/auth.store';
import { AccessRefreshToken, ApiError, HTTPMethod } from '@/types';

const API_URL = import.meta.env.VITE_API_URL;

type Options = {
  withoutAuth?: boolean;
  controller?: AbortController;
  timeout?: number;
  responseType?: 'json' | 'blob';
};

interface FetchResponse<T> {
  body: T;
  status: number;
  headers: Headers;
}

const refreshTokenMutex = new Mutex();

/**
 * @throws {ApiError}
 */
export const fetchApiBody = async <T>(
  endpoint: string,
  method: HTTPMethod,
  params?: Record<string, unknown> | null,
  body?: FormData | Record<string, unknown> | null,
  options: Options = {},
): Promise<T> => {
  return (await fetchApi<T>(endpoint, method, params, body, options)).body;
};

/**
 * @throws {ApiError}
 */
export const fetchApi = async <T>(
  endpoint: string,
  method: HTTPMethod,
  params?: Record<string, unknown> | null,
  body?: FormData | Record<string, unknown> | null,
  options: Options = {},
): Promise<FetchResponse<T>> => {
  const { timeout = 60000 } = options;

  const controller = options.controller ?? new AbortController();
  const accessToken = localStorage.getItem(LS_ACCESS_TOKEN_KEY);
  const refreshToken = localStorage.getItem(LS_REFRESH_TOKEN_KEY);
  const query = API_URL + endpoint + (params ? '?' + stringify(params) : '');

  if (import.meta.env.DEV)
    console.info(
      `%c[FetchAPI]%c[${method}] ${endpoint}`,
      'color: green',
      'color: null',
    );

  const fetchConfig: RequestInit = {
    method,
    headers: {
      Accept: 'application/json',
      ...(!options?.withoutAuth
        ? { Authorization: `Bearer ${accessToken}` }
        : {}),
    },
    signal: controller.signal,
  };

  fetchConfig.body = body as never;
  if (body && !(body instanceof FormData)) {
    fetchConfig.body = JSON.stringify(body);
    (fetchConfig.headers as Record<string, string>)['Content-Type'] =
      'application/json';
  }

  /* Set timeout */
  let timeoutId = setTimeout(() => controller.abort(), timeout);

  let response = await fetch(query, fetchConfig);
  clearTimeout(timeoutId);
  let bodyHandler = await BodyHandler.initialize(
    response,
    options.responseType,
  );

  if (!response.ok) {
    /* Check if throttle limit */
    if (response.status === 429) {
      toast.error(
        'Limite de requête atteinte. Merci de patienter quelques instants.',
      );
      throw new ApiError(response.statusText, 429);
    }

    /* Logs message if Bad request */
    if (response.status === 400) {
      console.info(
        '%c[FetchAPI][ERROR]%c Bad request:',
        'color: red',
        'color: null',
        bodyHandler.getErrorMessage(),
      );
    }

    /* Try to use refresh token if exists */
    if (response.status === 401 && !options.withoutAuth && refreshToken) {
      const authTokens = await (refreshTokenMutex.isLocked()
        ? refreshTokenMutex.waitForUnlock().then(() => ({
            accessToken: localStorage.getItem(LS_ACCESS_TOKEN_KEY),
            refreshToken: localStorage.getItem(LS_REFRESH_TOKEN_KEY),
          }))
        : applyRefreshToken(refreshToken));

      if (authTokens?.accessToken) {
        (fetchConfig.headers as Record<string, string>).Authorization =
          `Bearer ${authTokens.accessToken}`;
        timeoutId = setTimeout(() => controller.abort(), timeout);
        response = await fetch(query, fetchConfig);
        clearTimeout(timeoutId);
        if (response.status !== 204) {
          bodyHandler = await BodyHandler.initialize(
            response,
            options.responseType,
          );
        }
      }
    } else if (response.status && !refreshToken) {
      useAuthStore.getState().logout();
    }

    if (!response.ok) {
      throw new ApiError(
        bodyHandler.getErrorMessage(),
        response.status,
        bodyHandler.body.error,
      );
    }
  }

  return {
    body: bodyHandler.body as T,
    status: response.status,
    headers: response.headers,
  };
};

const applyRefreshToken = async (
  refreshToken: string,
): Promise<AccessRefreshToken | null> => {
  const release = await refreshTokenMutex.acquire();

  try {
    const { body: response } = await fetchApi<AccessRefreshToken>(
      '/auth/sign-in/refresh',
      'POST',
      null,
      { refreshToken },
      { withoutAuth: true },
    );

    localStorage.setItem(LS_ACCESS_TOKEN_KEY, response.accessToken);
    localStorage.setItem(LS_REFRESH_TOKEN_KEY, response.refreshToken);

    return response;
  } catch {
    useAuthStore.getState().logout();
    return null;
  } finally {
    release();
  }
};

class BodyHandler {
  private _response?: Response;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private _json?: any;
  private _blob?: Blob;

  private constructor() {}

  static async initialize(
    response: Response,
    responseType?: Options['responseType'],
  ): Promise<BodyHandler> {
    const instance = new BodyHandler();
    if (response.status === 204) return instance;

    const contentType = response.headers.get('Content-Type')?.split(';')[0];

    if (responseType == undefined) {
      if (contentType === 'application/json') {
        responseType = 'json';
      } else if (
        ['application/pdf', 'application/octet-stream'].includes(contentType!)
      ) {
        responseType = 'blob';
      }
    }

    instance._response = response;

    if (responseType === 'json') instance._json = await response.json();
    else if (responseType === 'blob') instance._blob = await response.blob();

    return instance;
  }

  getErrorMessage(): string {
    if (this._json) {
      return this._json?.message;
    }
    return this._response!.statusText;
  }

  public get body() {
    return this._json ?? this._blob;
  }
}
